import { createSlice } from '@reduxjs/toolkit';

function getInitialTheme() {
  const isDark = window.matchMedia('(prefers-color-scheme: dark)');
  if (isDark) {
    return 'dark';
  }
  return 'light';
}

const themeToggleSlice = createSlice({
  name: 'themeToggle',
  initialState: { theme: getInitialTheme() },
  reducers: {
    flip: (state) => {
      if (state.theme === 'light') {
        state.theme = 'dark';
      } else {
        state.theme = 'light';
      }
    },
  },
});

export const { flip } = themeToggleSlice.actions;

export default themeToggleSlice.reducer;
