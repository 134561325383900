import { Outlet, Navigate } from 'react-router-dom';
import React from 'react';
import SideBar from './SideBar';
import Header from './Header';
import { useAppSelector } from '@/store';

function ProtectedRoute() {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="h-full w-full flex flex-col">
      <SideBar />
      <div className="h-full flex flex-col sm:gap-4 sm:py-4 sm:pl-14 dark:dark:bg-black">
        <Header />
        <Outlet />
      </div>
    </div>
  );
}

export default ProtectedRoute;
