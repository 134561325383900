import Link from './Link';

import { Button } from './ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Input } from './ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useAppDispatch, useAppSelector } from '@/store';
import { AuthStatus, register } from '@/store/slices/authSlice';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@/hooks/use-toast';
import { GetCurrentHumanReadableDate } from '@/lib/time';

const FormSchema = z.object({
  firstname: z.string().min(1, 'Invalid first name'),
  lastname: z.string().min(1, 'Invalid last name'),
  email: z.string().email('Invalid email'),
  password: z.string().min(6, 'Too short password'),
});

function SignUpForm() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const navigate = useNavigate();

  const { toast } = useToast();

  const dispatch = useAppDispatch();
  const { status, error } = useAppSelector((s) => s.auth);

  const isLoading = status === AuthStatus.Loading;

  useEffect(() => {
    switch (status) {
      case AuthStatus.Succeeded:
        navigate('/login');
        break;
      case AuthStatus.Failed:
        toast({
          title: error as string,
          description: GetCurrentHumanReadableDate(),
        });
        break;
    }
  }, [status, error, navigate, toast]);

  const onSignUp = (formData: z.infer<typeof FormSchema>, e: any) => {
    const actionType = (e.nativeEvent.submitter as HTMLButtonElement).name;

    switch (actionType) {
      case 'simple':
        dispatch(
          register({
            email: formData.email,
            password: formData.password,
          })
        );
        break;
      case 'google-oauth':
        console.log('google oauth');
        break;
      default:
        console.error('unknown type of auth');
    }
  };

  return (
    <div className="h-full flex justify-center dark:bg-black">
      <Card className="mx-auto max-w-sm my-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSignUp)}>
            <CardHeader>
              <CardTitle className="text-xl">Sign Up</CardTitle>
              <CardDescription>
                Enter your information to create an account
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="firstname"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input
                              placeholder={'Max'}
                              disabled={isLoading}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="lastname"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input
                              placeholder={'Robinson'}
                              disabled={isLoading}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="grid gap-2">
                  <div className="grid gap-2">
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input
                              placeholder={'x@example.com'}
                              disabled={isLoading}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="grid gap-2">
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            disabled={isLoading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  name="simple"
                  type="submit"
                  disabled={isLoading}
                  className="w-full"
                >
                  Create an account
                </Button>
                <Button
                  name="google-oauth"
                  variant="outline"
                  disabled={true}
                  className="w-full"
                >
                  Sign up with Google
                </Button>
              </div>
              <div className="mt-4 text-center text-sm">
                Already have an account?{' '}
                <Link href="/" className="underline">
                  Login in
                </Link>
              </div>
            </CardContent>
          </form>
        </Form>
      </Card>
    </div>
  );
}

export default SignUpForm;
