import { Link as ReactRouterDomLink } from 'react-router-dom';
import React from 'react';

interface LinkProps {
  href: string;
  className?: string;
  children: React.ReactNode;
}

function Link({ href, className, children }: LinkProps) {
  return (
    <ReactRouterDomLink to={href} className={className}>
      {children}
    </ReactRouterDomLink>
  );
}

export default Link;
