import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Button } from './ui/button';
import { Copy } from 'lucide-react';
import { Separator } from './ui/separator';

function CallCard() {
  return (
    <div>
      <Card className="overflow-hidden" x-chunk="dashboard-05-chunk-4">
        <CardHeader className="flex flex-row items-start bg-muted/50">
          <div className="grid gap-0.5">
            <CardTitle className="group flex items-center gap-2 text-lg">
              Call Oe31b70H
              <Button
                size="icon"
                variant="outline"
                className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
              >
                <Copy className="h-3 w-3" />
                <span className="sr-only">Copy Order ID</span>
              </Button>
            </CardTitle>
            <CardDescription>Scheduled: November 23, 2023</CardDescription>
          </div>
          <div className="ml-auto flex items-center gap-1">
            <Button variant="outline">Export</Button>
            <Button
              variant="outline"
              className="hover:bg-red-600 active:bg-red-700"
            >
              Delete
            </Button>
          </div>
        </CardHeader>
        <CardContent className="p-6 text-sm">
          <div className="grid gap-3">
            <div className="font-semibold">Customer Information</div>
            <dl className="grid gap-3">
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Customer</dt>
                <dd>Liam Johnson</dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Email</dt>
                <dd>
                  <a href="mailto:">liam@acme.com</a>
                </dd>
              </div>
              <div className="flex items-center justify-between">
                <dt className="text-muted-foreground">Phone</dt>
                <dd>
                  <a href="tel:">+1 234 567 890</a>
                </dd>
              </div>
            </dl>
          </div>
          <Separator className="my-4" />
          <div className="grid gap-3">
            <div className="font-semibold">Call Transcript</div>
            <div>
              Salesperson: Hi there! Thanks for taking the time to chat with me
              today. How’s your day going? Customer: Pretty good, thanks. How
              about you? Salesperson: I’m doing well, thank you! So, I
              understand you're looking for a solution to improve your home
              office setup? Customer: Yeah, I’ve been thinking about upgrading
              my desk and chair. My current setup isn’t very comfortable for
              long hours of work. Salesperson: I completely understand. A good
              setup can make a huge difference in productivity and comfort. Have
              you considered our ergonomic office chair? It’s designed
              specifically for long hours of sitting, with adjustable features
              to fit your body perfectly. Customer: I’ve heard about ergonomic
              chairs, but I’m not sure if they’re really worth the investment.
              Salesperson: That’s a fair point. What’s great about our chair is
              that it’s not just about comfort, but also about supporting your
              posture and preventing back pain. Many of our customers have
              noticed a significant improvement in their comfort level and even
              in their energy throughout the day. Customer: That sounds good,
              but I’m also looking at other options. What makes your chair stand
              out from the competition? Salesperson: Great question! What sets
              our chair apart is its durable build and fully customizable
              settings. You can adjust the seat height, armrests, and lumbar
              support to match your exact needs. Plus, it comes with a 5-year
              warranty, so you can trust it’s built to last. Customer: That’s
              reassuring. How much does it cost, though? I don’t want to spend
              too much. Salesperson: Our chair is competitively priced at $399.
              While it’s a bit of an investment, the benefits in comfort and
              health really pay off in the long run. And right now, we’re
              offering a 10% discount for new customers, which brings the price
              down to $359. Customer: That’s not bad. I might need some time to
              think about it, though. Salesperson: Absolutely, take your time.
              If it helps, we also offer a 30-day trial period, so you can test
              it out in your own space and see if it’s the right fit for you. If
              you’re not satisfied, you can return it, no questions asked.
              Customer: That’s a good policy. I think I’ll give it a try.
              Salesperson: I’m glad to hear that! I’ll get your order started
              right away. You’re going to love the difference it makes in your
              workday. Would you like to take advantage of our 10% discount
              today? Customer: Yes, I’ll go ahead with the discount. Thanks for
              your help! Salesperson: My pleasure! I’ll send you a confirmation
              email shortly with all the details. If you have any more questions
              or need further assistance, feel free to reach out. Have a great
              day! Customer: Thanks, you too!
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default CallCard;
