import Dashboard from './components/Dashboard';
import { Route, Routes } from 'react-router-dom';
import LoginForm from './components/Login';
import ProtectedRoute from './components/PrivateRoute';
import Homepage from './components/Homepage';
import SignUpForm from './components/SignUp';
import NotFoundPage from './components/NotFoundPage';
import Prompt from './components/Prompt';
import Settings from './components/Settings';
import { useAppSelector } from './store';
import React from 'react';
import { Toaster } from '@/components/ui/toaster';

function App() {
  const themeToggle = useAppSelector((state) => state.themeToggle.theme);

  return (
    <div className={`${themeToggle} dark:bg-black w-full h-full`}>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Homepage />} />
          <Route path="/calls" element={<Dashboard />} />
          <Route path="/prompt" element={<Prompt />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
