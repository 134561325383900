import { Button } from './ui/button';
import Link from './Link';

function NotFoundPage() {
  return (
    <div className="h-full flex flex-col justify-center dark:bg-black">
      <div className="flex justify-center items-center resize-none">
        <img src="/lolu.png" alt="Lolu" className="select-none w-64 h-64" />
      </div>
      <div className="flex justify-center items-center">
        <span className="scroll-m-20 text-2xl font-semibold tracking-tight ml-2 dark:text-secondary-foreground">
          Ooops... Page Not Found
        </span>
      </div>
      <div className="flex justify-center mt-10 dark:text-secondary-foreground">
        <Button variant="outline">
          <Link href="/public">Go to Homepage</Link>
        </Button>
      </div>
    </div>
  );
}

export default NotFoundPage;
