import Link from './Link';

import { Button } from './ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Input } from './ui/input';
import { useAppDispatch, useAppSelector } from '@/store';
import { AuthStatus, login } from '@/store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useEffect } from 'react';
import { GetCurrentHumanReadableDate } from '@/lib/time';
import { useToast } from '@/hooks/use-toast';

const FormSchema = z.object({
  email: z.string().email('Invalid email'),
  password: z.string().min(6, 'Too short password'),
});

function LoginForm() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const dispatch = useAppDispatch();

  const { status, error } = useAppSelector((state) => state.auth);
  const isLoading = status === AuthStatus.Loading;

  const navigate = useNavigate();

  const { toast } = useToast();

  useEffect(() => {
    switch (status) {
      case AuthStatus.Succeeded:
        // navigate('/');
        // TODO: temporary fix for bug with react-router
        window.location.href = "/"
        break;
      case AuthStatus.Failed:
        toast({
          title: error as string,
          description: GetCurrentHumanReadableDate(),
        });
        break;
    }
  }, [status, error, navigate, toast]);

  const onLogin = (formData: z.infer<typeof FormSchema>, e: any) => {
    const actionType = e.nativeEvent.submitter.name;

    switch (actionType) {
      case 'simple':
        dispatch(
          login({
            username: formData.email,
            password: formData.password,
          })
        );
        break;
      case 'google-oauth':
        console.log('google oauth');
        break;
      default:
        console.error('unknown type of login');
    }
  };

  return (
    <div className="h-full flex justify-center dark:bg-black">
      <Card className="mx-auto max-w-sm my-auto">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onLogin)}>
            <CardHeader>
              <CardTitle className="text-2xl">Login</CardTitle>
              <CardDescription>
                Enter your email below to login to your account
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input
                            placeholder={'x@example.com'}
                            disabled={isLoading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid gap-2">
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center">
                          <FormLabel>Password</FormLabel>
                          <Link
                            href="#"
                            className="ml-auto inline-block text-sm underline"
                          >
                            Forgot your password?
                          </Link>
                        </div>
                        <FormControl>
                          <Input
                            type="password"
                            disabled={isLoading}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage className="text-xs" />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  name="simple"
                  type="submit"
                  disabled={isLoading}
                  className="w-full"
                >
                  Login
                </Button>
                <Button
                  name="google-oauth"
                  variant="outline"
                  disabled={true}
                  className="w-full"
                >
                  Login with Google
                </Button>
              </div>
              <div className="mt-4 text-center text-sm">
                Don&apos;t have an account?{' '}
                <Link href="/signup" className="underline">
                  Sign up
                </Link>
              </div>
            </CardContent>
          </form>
        </Form>
      </Card>
    </div>
  );
}

export default LoginForm;
