import axios from 'axios';
import store, { RootState } from '@/store';

const apiClient = axios.create({
  baseURL: 'https://api.lolu.ai',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const state = store.getState() as RootState;

    const token = state.auth.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
