export function GetCurrentHumanReadableDate(): string {
  const currentTime = new Date();
  return currentTime.toLocaleString('en-US', {
    weekday: 'long', // "Monday"
    year: 'numeric', // "2024"
    month: 'long', // "September"
    day: 'numeric', // "7"
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true, // Use false for 24-hour format
  });
}
