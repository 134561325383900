import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiClient from '@/lib/axios';
import axios from 'axios';
import { getUIErrorMessage } from '@/lib/errors';
import { ErrorResponse } from '@/store/slices/index';

export enum AuthStatus {
  Idle,
  Loading,
  Succeeded,
  Failed,
}

interface AuthState {
  status: AuthStatus;
  error: string | null;
  token: string | null;
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  status: AuthStatus.Idle,
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  error: null,
};

const authSlice = createSlice({
  name: 'userAuth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Register
    builder
      .addCase(register.pending, (state) => {
        state.status = AuthStatus.Loading;
        state.error = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.status = AuthStatus.Succeeded;
      })
      .addCase(
        register.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.status = AuthStatus.Failed;
          state.error = action.payload as string;
        }
      );

    // Login
    builder
      .addCase(login.pending, (state) => {
        state.status = AuthStatus.Loading;
        state.error = null;
      })
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<LoginResponse>) => {
          state.status = AuthStatus.Succeeded;
          state.token = action.payload.access_token;
          state.isAuthenticated = true;
          localStorage.setItem('token', state.token);
        }
      )
      .addCase(
        login.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.status = AuthStatus.Failed;
          state.error = action.payload as string;
        }
      );

    // Logout
    builder
      .addCase(logout.pending, (state) => {
        state.status = AuthStatus.Loading;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = AuthStatus.Idle;
        state.token = null;
        state.isAuthenticated = false;
        localStorage.removeItem('token');
      })
      .addCase(
        logout.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.status = AuthStatus.Failed;
          state.error = action.payload as string;
        }
      );
  },
});

interface RegisterParams {
  email: string;
  password: string;
}

interface RegisterResponse {
  id: string;
  email: string;
  is_active: boolean;
  is_verified: boolean;
}

export const register = createAsyncThunk<
  RegisterResponse,
  RegisterParams,
  ErrorResponse
>('auth/register', async (params: RegisterParams, { rejectWithValue }) => {
  try {
    const response = await apiClient.post<RegisterResponse>(
      '/auth/register',
      params
    );
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return rejectWithValue(getUIErrorMessage(err.response.status));
    }
    return rejectWithValue('Network error. Please check your connection.');
  }
});

interface LoginParams {
  username: string;
  password: string;
}

interface LoginResponse {
  token_type: string;
  access_token: string;
}

export const login = createAsyncThunk<
  LoginResponse,
  LoginParams,
  ErrorResponse
>('auth/login', async (params: LoginParams, { rejectWithValue }) => {
  try {
    const response = await apiClient.post<LoginResponse>(
      '/auth/login',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      return rejectWithValue(getUIErrorMessage(err.response.status));
    }
    return rejectWithValue('Network error. Please check your connection.');
  }
});

export const logout = createAsyncThunk<void, void, ErrorResponse>(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await apiClient.post<void>('/auth/logout');
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        return rejectWithValue(getUIErrorMessage(err.response.status));
      }
      return rejectWithValue('Network error. Please check your connection.');
    }
  }
);

export default authSlice.reducer;
