import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';
import { Button } from './ui/button';
import { ListFilter, Search } from 'lucide-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import { Badge } from './ui/badge';
import { Input } from './ui/input';

interface TableData {
  id: string;
  customer: string;
  phone: string;
  status: string;
  scheduled: string;
  date: string;
}

const mockData: TableData[] = [
  {
    id: '1',
    customer: 'Liam Johnson',
    phone: '+12134209090',
    status: 'Completed',
    scheduled: '2023-06-20',
    date: '2023-06-23',
  },
  {
    id: '2',
    customer: 'Olivia Smith',
    phone: '+12134209090',
    status: 'Declined',
    scheduled: '2023-06-24',
    date: '2023-06-26',
  },
  {
    id: '3',
    customer: 'Noah Williams',
    phone: '+12134209090',
    status: 'Completed',
    scheduled: '2023-06-20',
    date: '2023-06-23',
  },
  {
    id: '4',
    customer: 'Emma Brown',
    phone: '+12134209090',
    status: 'Completed',
    scheduled: '2023-06-20',
    date: '2023-06-23',
  },
  {
    id: '5',
    customer: 'Liam Johnson',
    phone: '+12134209090',
    status: 'Completed',
    scheduled: '2023-06-20',
    date: '2023-06-23',
  },
  {
    id: '6',
    customer: 'Noah Williams',
    phone: '+12134209090',
    status: 'Completed',
    scheduled: '2023-06-20',
    date: '2023-06-23',
  },
];

function CallLogs() {
  return (
    <Tabs defaultValue="outbound">
      <div className="flex items-center">
        <TabsList>
          <TabsTrigger value="outbound">Outbound</TabsTrigger>
          <TabsTrigger value="inbound">Inbound</TabsTrigger>
        </TabsList>
        <div className="relative ml-auto flex-1 md:grow-0">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder="Search..."
            className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px] dark:text-secondary-foreground"
          />
        </div>
        <div className="ml-5 flex items-center gap-2 dark:text-secondary-foreground">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-7 gap-1 text-sm">
                <ListFilter className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only">Filter</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Filter by</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem checked>All</DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Scheduled</DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Completed</DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Failed</DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <TabsContent value="outbound">
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="px-7">
            <CardTitle>Outbound Calls</CardTitle>
            <CardDescription>
              Recent outbound calls from your store.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Customer</TableHead>
                  <TableHead className="hidden sm:table-cell">Phone</TableHead>
                  <TableHead className="hidden sm:table-cell">Status</TableHead>
                  <TableHead className="hidden md:table-cell">
                    Scheduled
                  </TableHead>
                  <TableHead className="text-right">Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mockData.map((row) => {
                  return (
                    <TableRow /*className="bg-accent"*/ key={row.id}>
                      <TableCell>
                        <div className="font-medium">{row.customer}</div>
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        {row.phone}
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        <Badge className="text-xs" variant="outline">
                          {row.status}
                        </Badge>
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        {row.scheduled}
                      </TableCell>
                      <TableCell className="text-right">{row.date}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="inbound">
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="px-7">
            <CardTitle>Inbound Calls</CardTitle>
            <CardDescription>
              Recent inbound calls from your store.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Customer</TableHead>
                  <TableHead className="hidden sm:table-cell">Phone</TableHead>
                  <TableHead className="hidden sm:table-cell">Status</TableHead>
                  <TableHead className="hidden md:table-cell">
                    Scheduled
                  </TableHead>
                  <TableHead className="text-right">Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {mockData.map((row) => {
                  return (
                    <TableRow /*className="bg-accent"*/ key={row.id}>
                      <TableCell>
                        <div className="font-medium">{row.customer}</div>
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        {row.phone}
                      </TableCell>
                      <TableCell className="hidden sm:table-cell">
                        <Badge className="text-xs" variant="outline">
                          {row.status}
                        </Badge>
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        {row.scheduled}
                      </TableCell>
                      <TableCell className="text-right">{row.date}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}

export default CallLogs;
